import * as React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  CRestaurantJumbotron,
  CBreadCrumb,
  LWrap,
  Search,
  LRestaurantPickup,
  CHeroMedia,
  CSectHeading,
  CFixedImg,
  CHeroImg,
  CSpSliderCard,
  CSectTitle,
  CBtnList,
  LMenu,
  LRestaurant,
  CPanelCard,
  LReservation,
  ShopContents,
  LRestaurantContact,
  CCard,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import { shopFixedChoice } from '../../../utils/shop-choice';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        size="large"
        data={{
          location: {
            floor: '1F',
            category: 'デリカ＆ラウンジ',
          },
          logo: {
            src: '/assets/images/restaurants/coffret/logo_top.svg',
            alt: '',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/coffret/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/coffret/kv__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/restaurants/coffret/kv02.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/coffret/kv02__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/restaurants/coffret/kv03.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/coffret/kv03__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン&バー',
                path: '/restaurants/',
              },
            ],
            current: {
              label: '1F デリカ＆ラウンジ コフレ',
            },
          }}
        />
      </CRestaurantJumbotron>
      <Search formId="toppage" currentIndex={1} selected="yrph-coffret" />
      <section className="l_sect">
        <LWrap>
          <CHeroMedia
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/coffret/img_coffret.png',
                  alt: '',
                },
                title: (
                  <>
                    フランス語で「小箱」
                    <br />
                    の意味を持つ「コフレ」
                  </>
                ),
                text: (
                  <>
                    デリカショップのショーケースは、まるで「小箱」のよう。
                    <br />
                    ホテルメイドのケーキやブレッドなどのテイクアウト商品が並びます。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/restaurants/coffret/img_coffret02.png',
                  alt: '',
                },
                title: <>開放感のあるラウンジ</>,
                text: (
                  <>
                    「ドッグヤードガーデン」を見渡すラウンジでは、ケーキや豊富なドリンクメニューをはじめ、アフタヌーンティーもお楽しみいただけます。時間ごとに表情を変える景色を楽しみながら、優雅なひとときを。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbLarge">
        <LWrap>
          <CSectHeading
            data={{
              title: (
                <>
                  ORIGINAL <br className="u_sp" />
                  MENU
                </>
              ),
              text: (
                <>
                  季節のフルーツを使ったスイーツや
                  <br />
                  アフタヌーンティー、
                  <br />
                  毎朝丁寧に焼き上げるブレッドなど、
                  <br />
                  多彩なラインナップを
                  <br />
                  ご用意しております。
                </>
              ),
            }}
          />
          <CHeroImg
            exClass="u_mb40"
            img={{
              src: '/assets/images/restaurants/coffret/img_coffret12.png',
              alt: '',
            }}
          />
          <CSpSliderCard
            exClass="u_mb40"
            col={3}
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/coffret/img_coffret03.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/coffret/img_coffret04.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/coffret/img_coffret05.png',
                  alt: '',
                },
              },
            ]}
          />
          <CHeroMedia
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/coffret/img_coffret07.png',
                  alt: '',
                },
                title: <></>,
                text: (
                  <>
                    季節のスイーツやホテルで焼き上げるブレッドなどご自宅でお楽しみいただける商品や、贈り物にも最適なホテルオリジナルギフト商品もご用意。
                    <br />
                    ラウンジでは季節の移ろい、時間ごとに表情を変える景色を楽しみながら、優雅なティータイムをお過ごしいただけます。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section>
        <LWrap>
          <CSectTitle
            title={{
              en: 'RESTAURANT',
              ja: '店舗情報',
            }}
          />
          <ShopContents
            data={shopFixedChoice(shopGet(), 'coffret_top').contents1}
          />
        </LWrap>
      </section>
      <LMenu
        data={[
          {
            img: {
              src: '/assets/images/restaurants/coffret/img_menu.png',
            },
            imgSp: {
              src: '/assets/images/restaurants/coffret/img_menu__sp.png',
            },
            title: <>LOUNGE</>,
            btn: {
              label: <>ラウンジ</>,
              link: {
                href: '/restaurants/coffret/menu/#teatime',
              },
            },
          },
          {
            img: {
              src: '/assets/images/restaurants/coffret/img_menu02.png',
            },
            imgSp: {
              src: '/assets/images/restaurants/coffret/img_menu__sp02.png',
            },
            title: <>DELICA SHOP</>,
            btn: {
              label: <>デリカショップ</>,
              link: {
                href: '/restaurants/coffret/menu/#delica',
              },
            },
          },
          {
            img: {
              src: '/assets/images/restaurants/coffret/img_menu03.png',
            },
            imgSp: {
              src: '/assets/images/restaurants/coffret/img_menu__sp03.png',
            },
            title: <>GIFT</>,
            btn: {
              label: <>ギフト</>,
              link: {
                href: '/restaurants/coffret/menu/#gift',
              },
            },
          },
        ]}
      />
      <CBtnList
        exClass="u_mbLarge"
        data={[
          {
            label: 'すべてのメニューを見る',
            link: {
              href: '/restaurants/coffret/menu/',
            },
          },
        ]}
      />
      <Link
        to="https://rsv.ihonex.com/cgi-bin/shopping/goods_list.cgi?hid=rphs_yokohama&form=jp"
        target="_blank"
      >
        <CFixedImg
          width={840}
          img={{
            src: '/assets/images/restaurants/coffret/img_coffret11.png',
            alt: '',
          }}
        />
      </Link>
      <LRestaurantPickup restaurant={['コフレ']} />
      <section>
        <LRestaurant />
      </section>
      <section className="l_sect">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
            <li>料金には消費税、サービス料が含まれます。</li>
            <li>料理の表示価格は、特別な記載がない限り1名様分の料金です。</li>
            <li>
              テイクアウト商品の料金には消費税（8％）のみ含まれます。コフレ店内をご利用の場合は、サービス料を加算、消費税は10％となります。
            </li>
            <li>入荷状況によりメニュー内容に変更がある場合がございます。</li>
            <li>
              「神奈川県公共的施設における受動喫煙防止条例」に基づきレストラン・バーを全席禁煙とさせていただいております。
            </li>
            <li>
              ご入店の際は、タンクトップ・短パン・ビーチサンダルなどはご遠慮いただいております。
            </li>
          </ul>
        </LWrap>
      </section>
      <LRestaurantContact selected="yrph-coffret" />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
